<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Kenaikan Pangkat (Bulan ini)</p>
          </v-card-actions>
          <v-divider />
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="employees"
            :items-per-page="5"
          >
            <template v-slot:[`item.nip`]="{ item }">
              <span
                class="hover-primary"
                @click="getToPage('employee.detail')"
                >{{ item.nip }}</span
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.status == 'Tertunda'"
                text
                small
                @click="handleCall(item)"
                color="primary"
                class="text-capitalize"
                >Panggil</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "NIP", value: "nip", sortable: false, align: "center" },
        {
          text: "ID PRESENSI",
          value: "id_presensi",
          sortable: false,
          align: "center"
        },
        { text: "NAMA", value: "nama", sortable: false, align: "center" },
        {
          text: "UNIT KERJA",
          value: "unit_kerja",
          sortable: false,
          align: "center"
        },
        { text: "STATUS", value: "status", sortable: false, align: "center" },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      employees: [
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Arif Wicaksono",
          unit_kerja: "Staff Bag Tata Usaha Direktorat SMP",
          status: "Tertunda"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Budi Kurniawan",
          unit_kerja: "Staff Bag Tata Usaha Direktorat SMP",
          status: "Diproses"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Nadia Karimah",
          unit_kerja: "Staff Bag Tata Usaha Direktorat SMP",
          status: "Tertunda"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Rosalinda",
          unit_kerja: "Staff Bag Tata Usaha Direktorat SMP",
          status: "Diproses"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Hermawan",
          unit_kerja: "Staff Bag Tata Usaha Direktorat SMP",
          status: "Tertunda"
        }
      ]
    };
  },
  methods: {
    handleCall() {}
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
